import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import Image from "gatsby-plugin-sanity-image"
import { BlockSerializer } from "../utils/BlockSerializer"
import { TOCSerializer } from "../utils/TOCSerializer"
import { ReadNext } from "../components/common/ReadNext"

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location, pageContext }) => {
    const ghostPost = data.ghostPost
    const sanityPost = data.sanityPost
    const post = sanityPost != null ? sanityPost : ghostPost
    const linkDisclaimertext = pageContext.lang == 'am' ? 'Եթե դուք ապրանքներ գնեք հղումների միջոցով, դուք կօգնեք «Սկանդինավյան Աշխարհ» կայքին: Գինը Ձեզ համար կմնա անփոփոխ:' 
    : 'Hvis dere kjøper produkter via lenkene, kan Scandinavian World tjene en provisjon uten ekstra kostnad for dere'

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
                language={pageContext.lang}
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout language={pageContext.lang}>
                <div className="container">
                    <article className="content">
                        { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>
                            { blockHasAmazonAffiliateLinks(post._rawBody) && <p style={{fontSize: 12, lineHeight: 1}}>{linkDisclaimertext}</p>}

                            {/* The main post content */ }
                            {sanityPost == null ? <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                            :
                            <>
                                <figure className="post-figure">
                                    <Image
                                        // pass asset, hotspot, and crop fields
                                        {...post.mainImage}
                                        // tell Sanity how large to make the image (does not set any CSS)
                                        width={800}
                                        alt={post.mainImage.alt}
                                        // style it how you want it
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <figcaption>
                                        {post.mainImage.caption} {post.mainImage.author != null && `, © ${post.mainImage.author}`}
                                    </figcaption>
                                </figure>
                                { post.withToC ? 
                                    <>
                                        <h2>{pageContext.lang == 'am' ? 'Բովանդակություն' : 'Innholdsfortegnelse'}</h2>
                                        <TOCSerializer>{post._rawBody}</TOCSerializer>
                                        <BlockSerializer withToC={true}>{post._rawBody}</BlockSerializer>
                                    </> 
                                    :
                                    <BlockSerializer>{post._rawBody}</BlockSerializer>
                                }
                                <div>
                                    <div className="flex">
                                        <Image
                                            // pass asset, hotspot, and crop fields
                                            {...post.author.image}
                                            // tell Sanity how large to make the image (does not set any CSS)
                                            width={400}
                                            alt={post.mainImage.alt}
                                            // style it how you want it
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                objectFit: "cover",
                                            }}
                                        />
                                        <div>
                                            <p style={{'fontSize':'16px', 'margin':'0 0 0 0', 'lineHeight':'1em'}}>{post.author.article_by_text}</p>
                                            <div className="flex">
                                                <div style={{'fontSize':'20px'}}>{post.author.name}</div>
                                                <div className="flex" style={{'gap':'10px'}}>
                                                    {post.author.twitter && <a href={post.author.twitter} target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/twitter_color.png" alt="Twitter" /></a>}
                                                    {post.author.facebook && <a href={post.author.facebook} target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook_color.png" alt="Facebook" /></a>}
                                                    {post.author.youtube && <a href={post.author.youtube} target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/youtube.svg" alt="Youtube" /></a>}
                                                </div>
                                            </div>

                                            <div style={{'fontSize':'16px','lineHeight': '1.35em'}}>
                                                <BlockSerializer>{post.author._rawBioShort}</BlockSerializer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {post.readNext?.enabled ? <ReadNext>{post.readNext}</ReadNext> : null}
                            </>}
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }),
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        sanityPost(slug: {current: {eq: $slug}}) {
            ...SanityPostFields
        }
    }
`

function blockHasAmazonAffiliateLinks(blocks = []) {
    for (let block of blocks) {
        if (block._type !== 'block') {
            continue;
        }

        for (let markDef of block.markDefs) {
            if (markDef._type == 'link' && /amzn.to|amazon|tag=/.test(markDef.href)) {
                return true;
            }
        }
    }

    return false;
}
