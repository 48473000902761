import React from "react"
import { Link } from "gatsby"
import { ReadNextPostCardFeed } from "./ReadNextPostCardFeed"

export const ReadNext = ({children}) => {
  const readNext = children

  return (
    <>
      <h2>{readNext.title}</h2>
      <p></p>
      {readNext.style == 'card' ?
        <ReadNextPostCardFeed>{readNext}</ReadNextPostCardFeed>
        : // style = 'text' // Currently we have only two types
        <ul>
          {readNext.readNextArticles.map((article, id) => (
            <li key={id}>
              <Link to={`/${article.slug.current}/`}>{article.title}</Link>
            </li>
          ))}
        </ul>
      }
    </>
  )
}