import React from "react"
import { PortableText, toPlainText } from '@portabletext/react'
import slugify from 'slugify'
import { Link } from 'gatsby'
import {
  SeoFriendlyImageRenderer,
  YoutubeRenderer,
  FacebookVideoRenderer,
  CustomHtmlRenderer,
  ContactFormRenderer,
  EmailSignupRenderer,
  TableRenderer,
  ColumnRenderer,
} from "./BlockRenderers"

export const TOCSerializer = (props) => {
  return (
    <ul class="toc">
      <PortableText 
        value={props.children} 
        components={
          serializer(false)
        }
      />
    </ul>
  )
}

const serializer = () => {
  return ({
    block: {
      h1: ({children}) => null,
      normal: ({children}) => null,
      h3: ({children}) => null,
      h4: ({children}) => null,
      h5: ({children}) => null,
      h6: ({children}) => null,
      h7: ({children}) => null,
      blockquote: ({children}) => null,
      h2: ({children, value}) => {
        const headerText = toPlainText(value)
        if (headerText == '') 
          return null
        return <li><a href={`#${slugify(headerText).toLowerCase()}`}>{children}</a></li>
      }
    },
    list: {
      bullet: ({children}) => null,
      number: ({children}) => null,
  
      // Ex. 2: rendering custom lists
      //checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
    },
  })
}